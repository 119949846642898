import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <RightContainer>
                        <h1>
                            <a>
                                <LogoBox to="/">
                                    <Logo
                                        src={require("./assets/images/main-logo.svg").default}
                                        alt="logo"
                                    />
                                </LogoBox>
                            </a>
                        </h1>
                    </RightContainer>
                    <ContentBox>
                        <Heading>Privacy Policy</Heading>
                        <Para>
                            UKXPRESS is committed to protecting the privacy of its users. This
                            Privacy Policy explains how we collect, use, disclose, and safeguard
                            your personal information when you use our services, including course
                            search, job search, accommodation search, pick and drop requests, and
                            visa enquiries all around the UK. By accessing or using our services,
                            you agree to the terms of this Privacy Policy. If you do not agree with
                            the practices described in this Privacy Policy, please do not use our
                            services.
                        </Para>
                        <BottomBox>
                            <InfoBox>
                                <SubHeading>1. Information We Collect</SubHeading>
                                <SubPara>
                                    We may collect personal information from you when you
                                    voluntarily provide it to us or when you use our services. The
                                    types of personal information we may collect include:
                                </SubPara>
                                <MainList>
                                    <SubList>● Name</SubList>
                                    <SubList>
                                        ● Contact information (e.g., email address, phone number)
                                    </SubList>
                                    <SubList>● Location information</SubList>
                                    <SubList>● Academic/professional information</SubList>
                                    <SubList>● Visa-related information</SubList>
                                    <SubList>● Other information you choose to provide</SubList>
                                </MainList>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>2. Use of Information</SubHeading>
                                <SubPara>
                                    We may use the personal information we collect for various
                                    purposes, including:
                                </SubPara>
                                <MainList>
                                    <SubList>● Providing and improving our services</SubList>
                                    <SubList>
                                        ● Facilitating course, job, and accommodation searches and
                                        matching services
                                    </SubList>
                                    <SubList>
                                        ● Processing and facilitating pick and drop requests
                                    </SubList>
                                    <SubList>
                                        ● Assisting with visa inquiries and related services
                                    </SubList>
                                    <SubList>
                                        ● Communicating with you and responding to your inquiries
                                    </SubList>
                                    <SubList>
                                        ● Sending you newsletters, updates, and promotional
                                        materials (if you have opted in)
                                    </SubList>
                                    <SubList>
                                        ● Conducting research and analysis to enhance our offerings
                                    </SubList>
                                </MainList>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>3. Sharing of Information</SubHeading>
                                <SubPara>
                                    We may share your personal information with third parties in the
                                    following circumstances:
                                </SubPara>
                                <MainList>
                                    <SubList>
                                        ● Service Providers: We may share your information with
                                        third-party service providers who assist us in delivering
                                        our services. These service providers have access to your
                                        personal information only to perform specific tasks on our
                                        behalf and are obligated not to disclose or use it for any
                                        other purpose.
                                    </SubList>
                                    <SubList>
                                        ● Business Partners: We may share your information with
                                        trusted business partners who offer complementary services
                                        or promotions. However, we will only do so with your
                                        explicit consent.
                                    </SubList>
                                    <SubList>
                                        ● Legal Requirements: We may disclose your personal
                                        information if required to do so by law or in response to
                                        valid requests from public authorities.
                                    </SubList>
                                </MainList>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>4. Data Security</SubHeading>
                                <SubPara>
                                    We have implemented reasonable measures to safeguard the
                                    personal information we collect. However, please note that no
                                    method of transmission or storage is completely secure, and we
                                    cannot guarantee the absolute security of your personal
                                    information.
                                </SubPara>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>5. Your Choices</SubHeading>
                                <SubPara>
                                    You have the right to access, update, correct, and delete your
                                    personal information. You may also choose to opt-out of
                                    receiving promotional communications from us. Please contact us
                                    using the information provided at the end of this Privacy Policy
                                    to exercise your rights or make any inquiries or requests.
                                </SubPara>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>6. Third-Party Links</SubHeading>
                                <SubPara>
                                    Our services may contain links to third-party websites or
                                    services. Please note that we are not responsible for the
                                    privacy practices of such third parties. We encourage you to
                                    review the privacy policies of those third parties before
                                    providing any personal information.
                                </SubPara>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>7. Changes to this Privacy Policy</SubHeading>
                                <SubPara>
                                    We reserve the right to modify this Privacy Policy at any time.
                                    Any changes will be effective immediately upon posting on our
                                    website or notifying you through other reasonable means. We
                                    encourage you to review this Privacy Policy periodically for any
                                    updates.
                                </SubPara>
                            </InfoBox>
                            <InfoBox>
                                <SubHeading>8. Contact Us</SubHeading>
                                <SubPara>
                                    If you have any questions, concerns, or requests regarding this
                                    Privacy Policy or our practices, please contact us at
                                    ukxpresskerala@gmail.com [India] or info@ukxpress.co.uk
                                    [London].
                                </SubPara>
                            </InfoBox>
                            <BottomPara>
                                By using our services, you signify your acceptance of this Privacy
                                Policy.
                            </BottomPara>
                        </BottomBox>
                    </ContentBox>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Privacy;

const MainContainer = styled.div`
    padding: 20px;
`;
const WrapperContainer = styled.div``;

const RightContainer = styled.div`
    margin-bottom: 40px;
    width: 8%;
    @media all and (max-width: 980px) {
        width: 10%;
    }
    @media all and (max-width: 640px) {
        width: 16%;
    }
    @media all and (max-width: 768px) {
        width: 14%;
    }
    @media all and (max-width: 480px) {
        width: 20%;
    }
    @media all and (max-width: 360px) {
        width: 18%;
    }
    h1 {
        width: 100%;
        line-height: 65%;
        a {
            display: inline-block;
            width: 100%;
        }
        @media all and (max-width: 1600px) {
            width: 90%;
        }
    }
`;
const LogoBox = styled(NavLink)``;
const Logo = styled.img`
    width: 100%;
    display: block;
    height: 100%;
`;
const ContentBox = styled.div``;
const Heading = styled.h1`
    font-family: "poppins_medium";
    font-size: 40px;
    margin-bottom: 40px;
`;
const Para = styled.p`
    font-family: "poppins_regular";
    font-size: 20px;
    margin-bottom: 50px;
`;
const BottomBox = styled.div``;
const InfoBox = styled.div`
    margin-bottom: 30px;
`;
const SubHeading = styled.h2`
    font-family: "poppins_medium";
    margin-bottom: 15px;
`;

const SubPara = styled.p`
    font-family: "poppins_regular";
    font-size: 20px;
    margin-bottom: 20px;
`;
const MainList = styled.ul``;
const SubList = styled.li`
    margin-bottom: 15px;
    font-size: 18px;
`;
const BottomPara = styled.p``;
