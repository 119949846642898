import react, { useEffect, useState } from "react";
import AppRouter from "../routers/AppRouter";
import OfflinePage from "../../includes/OfflinePage";

function MainRouter() {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);
    return <div>{isOnline ? <AppRouter /> : <OfflinePage />}</div>;
}

export default MainRouter;
