import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./components/assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import "./components/assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AOS } from "aos";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 480px)");

        const handleMediaQueryChange = (mq) => {
            if (mq.matches) {
                AOS.refreshHard();
            } else {
            }
        };

        mediaQuery.addListener(handleMediaQueryChange);

        return () => mediaQuery.removeListener(handleMediaQueryChange);
    }, []);
    return (
        <>
            <Router>
                <MainRouter />
            </Router>
        </>
    );
}

export default App;
